import React from 'react';
import './bookRelease.scss'
import TagPill from './tagPill';
import Spacer from './spacer';


const BookRelease = (props) => {

	return(

		<div className="book-release-container">
			
			<div className="book-thumbnail"> {props.image} </div>
			<div className="book-title"> {props.title} </div>
			<TagPill tag={ props.language } />
			<button className="book-release-button" onClick={()=> window.open( props.purchaseLink, "_blank")}>
			Buy Physical Edition</button>

		</div>

	);

}

export default BookRelease;
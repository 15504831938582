import React from 'react';


const Spacer = () => {

	return(

		<div style= {{ height: "30px" }}>
		</div>

	);

}

export default Spacer;
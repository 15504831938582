import React from 'react';
import './buttonBlock.scss';
import { Link } from 'react-router-dom';

const ButtonBlock = (props) => {

	return(

		<Link to={ props.url } className="button-block-container">
			<img src={ props.img } className="link-thumbnail" />
			<div className="text-wrapper">
				<div className="button-block-super-title">
				{ props.superTitle }
				</div>
				<div className="button-block-title">
				{ props.title }
				</div>
			</div>
		</Link>

	);

}

export default ButtonBlock;
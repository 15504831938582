import React from 'react';
import './pressBlock.scss';

const PressBlock = (props) => {

	return(
		<div className="press-block-wrapper">
			<div className="article-date">
			{ props.date }
			</div>
			<div className="article">
				{ props.quote }
			</div>
			<a href={ props.link } className="article-link" target="_blank">
				{ props.linkText } 
			</a>
		</div>

	);
}

export default PressBlock;
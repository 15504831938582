import React from 'react';
import { ReactComponent as BackIcon } from './../Assets/icons/BackArrow_InkyPen.svg'
import './backArrowIcon.scss'


const BackArrowIcon = () => {

	return(

		<div className="back-button-container">
			<BackIcon className={"back-arrow-icon"} />
		</div>

	);

}

export default BackArrowIcon;
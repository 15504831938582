import React from 'react';
import './404.scss';
import { Link } from 'react-router-dom';

export default function NoPage() {

  	return (
	  	<div className="no-page-container">
		  	<div className="no-page-title">
		  	404
		  	</div>

		  	<div className="no-page-header">
		  	Looks like someone took a wrong turn somewhere!
		  	</div>

			 <div className="no-page-description">
		  	The page you're looking for may no longer exist, or there might be a typo in your 
		  	link. Return to the <Link className="no-page-home-link" to="/home">Home Page</Link> to get back to square one!
		  	</div>

	  	</div>

  );
}
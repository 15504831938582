import React, { useState} from 'react';
import './gallery.scss';
import ImageGallery from './imageGallery';

const Gallery = (props) => {

	const galleryImages = props.images;
	const [IsExpanded, setIsExpanded] = useState(false);

	const expand = (val) => {

		if(IsExpanded) {
			setIsExpanded(false);

		} else {
			document.body.style.overflow = 'hidden'
			setIsExpanded(true);
			setImageVal(val);
		}
	}

	const [ImageVal, setImageVal] = useState(null);

	function showImageGalleryIfExpanded() {
		if(IsExpanded){
			return(
				<ImageGallery
				img= { ImageVal.src }
				onClose={()=> setIsExpanded(false) } 
				/>
				)

		} document.body.style.overflow = 'visible' 
		return null
	}
	

	return(
		<>

		{ showImageGalleryIfExpanded() }

		<div className={ galleryImages.length != 0 ? "gallery-container" : "hide" }>
		
			

			<div className="main-body-header">
				Gallery
			</div>

			<div className="gallery-wrapper">
				<div className="gallery-thumbnail-wrapper">
						{ galleryImages.map(val=>(
							<img onClick={ ()=> expand(val) } className="gallery-image" src= { val.src } alt= { val.alt } />
						))
					}
				</div>
			</div>

		</div>

		</>
	);
}

export default Gallery;
import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './menu.scss'

const Menu = ({show}) => {

	const menuItem = [
			{
				text: "Home",
				link: "/",
				isHeader: false,
			},
			{
				text: "Series",
				isHeader: true,
			},
			{
				text: "Bounties",
				link: "/series/Bounties",
				isHeader: false,
			},
			{
				text: "No-Good Heroes",
				link: "/series/No-Good-Heroes",
				isHeader: false,
			},
			{
				text: "Keep Busy",
				link: "/series/Keep-Busy",
				isHeader: false,
			},
			{
				text: "Eos",
				link: "/series/Eos",
				isHeader: false,
			},
			{
				text: "Tome of Tiny Tales",
				link: "/series/Tome-of-Tiny-Tales",
				isHeader: false,
			},
						{
				text: "About",
				link: "/about",
				isHeader: false,
			},
		
		]

	return(

		<>
			<div className={show ? "menu-container active" : "menu-container"}>
				<div className="button-wrapper">
					{menuItem.map(val => (
					<ul>
						<li> <Link className={ val.isHeader == false ? "menu-text" : "menu-header" } to={ val.link }> { val.text } </Link> </li>
					</ul>
					))
					}
				</div>
			</div>
			<div className={show ? "menu-glow active" : "menu-glow"} />
		</>
		);
}

export default Menu;
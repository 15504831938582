import React from 'react';
import './tagPill.scss'
// import { useState, setState } from 'react';

const TagPill = (props) => {

		if(props.isWarning){
			return(
			<div className="warning-tag"> {props.tag} </div>
			);
		}
		else {
			return(
			<div className="genre-tag"> {props.tag} </div>
			);
		}
	
}

export default TagPill;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import './fonts/JosefinSans-VariableFont_wght.ttf';
// import { Helmet, HelmetProvider } from 'react-helmet-async';

const render = () => {
  ReactDOM.render(
      
        <App />,
    
  document.getElementById('root')
  );
};
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
render();
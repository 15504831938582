import React from 'react'
import './seriesThumbnail.scss'
import { Link } from 'react-router-dom';

const SeriesThumbnail = (props) => {

let seriesLink = "/series/" + props.link;

  return (
  	<Link className="thumbnail-container" to= { seriesLink } >
      <div className="thumbnail-image">
  		{ props.image }
            <div className="thumbnail-image-glow">
      { props.image }
      </div>
      </div>

      <div className="thumbnail-title">
      { props.title }
      </div>
    </Link>
  );
}

export default SeriesThumbnail;
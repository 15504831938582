import React from 'react';
import PressBlock from './pressBlock';
import './pressAndReviews.scss';

const PressAndReviews = (props) => {

	const articles = props.pressArticles ;

	return(
		<div className={ articles.length != 0 ? "press-and-reviews-wrapper" : "hide" }>
			<div className="main-body-header">
				Press and reviews
			</div>

			<div className="article-wrapper">
			{
				articles.map(val=>(
				<PressBlock
				date={ val.date }
				quote={ val.quote }
				linkText={ val.linkText }
				link={ val.link }
				/>
				))
			}
			</div>
		</div>
	);
}

export default PressAndReviews;
import React from 'react';
import './comicSpotlight.scss'
import Thumbnail from './../Assets/comic-spotlight/overtrykk-2022.jpg'
import TagPill from './tagPill';

const ComicSpotlight = () => {
	const SpotlightTitle = 'Overtrykk 2022'
	const SpotlightProductReleaseDate = 'April 26, 2024'
	const SpotlightDescription = '"Overtrykk 2022" released with a collection of fantastic stories from around Norway. 7 stories are collected in this book headlined by Ørjan Aarvik\'s "Brakkvatn" who has also created the beautiful cover art. "Skrømt" by Lars is a sci-fi horror story. The last enclave of humanity struggling day to day, scavenging for food to make ends meet, when a mysterious creature brings hope that things will finally change. The Short Story is available for sale in Norwegian. Lars\' story is available to read for free online.'
	const SpotlightTag = 'Anthology'
	const purchaseLink = 'https://www.uberpress.no/products/overtrykk-2022'
	const readLink = 'https://tapas.io/episode/2758962'

	return(

		<div className="comic-spotlight-container">
			<div className="comic-spotlight-image-wrapper">
				<img alt={'Hero'} src={Thumbnail} className='spotlight-thumbnail' />
				<img alt={'Hero'} src={Thumbnail} className='spotlight-thumbnail-backplate' />
			</div>
			<div className="comic-spotlight-info-wrapper">

				<div className="comic-spotlight-title">
				{ SpotlightTitle }
				</div>
				
				<div className="comic-spotlight-tags-wrapper">
				<TagPill tag="Norwegian" />
				<TagPill tag="Anthology" />
				<TagPill tag="Horror" />
				<TagPill tag="Graphic Violence" isWarning/>
				</div>
				
				<div className="comic-spotlight-release-date">
				Released: { SpotlightProductReleaseDate }
				</div>
				
				<div className="comic-spotlight-description">
				{ SpotlightDescription }
				</div>
				
				<div className="comic-spotlight-button-wrapper">
					<button className="comic-spotlight-button" onClick={()=>window.open(purchaseLink, "_blank")}>
					Buy Physical Edition
					</button>
					<button className="comic-spotlight-button" onClick={()=>window.open(readLink, "_blank")}>
					Read Shade <span className="language-spec">English</span>
					</button>
				</div>
			</div>
		</div>

	);
}

export default ComicSpotlight;
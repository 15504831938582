import React from 'react';
import './videoBlock.scss';

const VideoBlock = (props) => {
	
	const videos = props.seriesVideos;
	console.log(videos)


	return(
		<div className={ videos.length != 0 ? "video-block-container" : "hide"}>
			<div className="main-body-header">
			Videos
			</div>
			<div className="video-wrapper">
				{
					videos.map(val=>
						<div className="video-container">
							<iframe
								className="video"
								src= { val.src }
								frameBorder="0"
								allow="accelerometer; encrypted-media; gyroscope; autoplay; picture-in-picture"
								allowFullScreen>
							</iframe>
						</div>
					)
				}
			</div>
		</div>
	);
}

export default VideoBlock;
import React from 'react';
import './footer.scss'
import { Link } from 'react-router-dom';

const Footer = () => {
	const d = new Date();
	let year = d.getFullYear();

	return(
		<div className="footer-container">
		<Link to="/about" className="about-link" >About Zilo Media</Link>
		<div className="copyright">
		Copyright @ { year } Zilo Media. All Rights Reserved.
		</div>
		</div>
		);
}

export default Footer;
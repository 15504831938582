import React from 'react';
import './creatorCredit.scss'

const CreatorCredit = (props) => {

	return(

		<div className="creator-credit-container">
			<div className="creator-name"> { props.name } </div>
			<div className="creator-title"> { props.title } </div>
		</div>

	);

}

export default CreatorCredit;
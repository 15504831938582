import React from 'react';
import './seriesPage.scss';
import Logo from './../Assets/bounties/Bounties-Logo.png';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import BackgroundArt from './../Assets/bounties/BountiesWebcomicCover_noLogo.jpg';
import Navbar from './../Components/navbar';
import TagPill from './../Components/tagPill';
import Footer from './../Components/footer';
import TapasLogo from './../Assets/external-logos/TAPAS_Logo.jpeg';
import WebtoonLogo from './../Assets/external-logos/WEBTOON_Logo.png';
import SeriesGeneralInfo from './../Components/seriesGeneralInfo';
import ReleasesAndCredits from './../Components/releasesAndCredits';
import PressAndReviews from './../Components/pressAndReviews';
import VideoBlock from './../Components/videoBlock';
import './../Components/videoBlock.scss';
import Gallery from './../Components/gallery';
import PressBlock from './../Components/pressBlock';
import BookRelease from './../Components/bookRelease';
import './../pages/seriesPage.scss';
import CreatorCredit from './../Components/creatorCredit';
import Spacer from './../Components/spacer';

/// BOOK COVERS
import BookThumbnail from './../Assets/no-good-heroes/UH-Bok_1-omslag-WEB.jpg';
import BookThumbnail2 from './../Assets/no-good-heroes/UH-Bok_2-omslag-WEB.jpg';
import BookThumbnail3 from './../Assets/no-good-heroes/UH-Bok_3-omslag-WEB.png';

/// GALLERY IMAGES
import img_01 from './../Assets/bounties/BountiesWebcomicCover_WEB.jpg'
import img_02 from './../Assets/bounties/GerlaProfilePicture.jpg'
import img_03 from './../Assets/bounties/Goblin01.jpg'
import img_04 from './../Assets/bounties/Goblin02.jpg'
import img_05 from './../Assets/bounties/Goblin03.jpg'
import img_06 from './../Assets/bounties/Goblin4_Sketch.jpg'
import img_07 from './../Assets/bounties/Goblin6.jpg'
// import img_08 from './../Assets/bounties/'
// import img_09 from './../Assets/bounties/'


export default function SeriesPage() {

///// TITLES AND LINKS

	const seriesTitle = 'No-Good-Heroes'
	const seriesDescription = 'When seven worlds collided, the Great Arbiter put a mark on all living things. A value separating the mighty from the weak, the notorious from the unknown. And value can always be taken… Scrupulous bounty hunters Gerla and Bern journey through this lawless world, chasing ever greater marks and perhaps discovering a semblance of purpose in their chaotic existence.'
	const tapasLink = null
	const webtoonLink = null


////// TAGS
	const tags = [
		{ 
			tag: "Action Fantasy",
			isWarning:false
		},
		{ 
			tag: "Comedy",
			isWarning:false
		},
		{ 
			tag: "Intense Cartoon Violence",
			isWarning:true,
		},
		{ 
			tag: "Strong Language",
			isWarning:true,
		},
		{ 
			tag: "Mild Nudity",
			isWarning:true,
		},
		{ 
			tag: "Mild Sexual Content",
			isWarning:true,
		},
	];

///// RELEASES AND CREDITS

	const isOnline = false;

	const myVolumes = [
		// {
		// 	title: "Udugelege Heltar, Volum 1: Stiv Kuling",
		// 	alt: "Volume 1",
		// 	thumbnail: BookThumbnail,
		// 	purchaseLink: "https://www.uberpress.no/products/udugelege-heltar-vol-1",
		// 	language: "Norwegian"
		// },
		// {
		// 	title: "Udugelege Heltar, Volum 2: Never av Stål",
		// 	alt: "Volume 2",
		// 	thumbnail:BookThumbnail2,
		// 	purchaseLink: "https://www.uberpress.no/products/udugelege-heltar-vol-2",
		// 	language: "Norwegian"
		// },
		// {
		// 	title: "Udugelege Heltar, Volum 3: Endelaus",
		// 	alt: "Volume 3",
		// 	thumbnail:BookThumbnail3,
		// 	purchaseLink: "https://www.uberpress.no/products/udugelege-heltar-volum-3-endelaus",
		// 	language: "Norwegian"
		// },

	];

	const seriesCreators = [
		{
			name: "Lars Erik Helgemo",
			title: "Artist/Writer"
		},
	];	


///// ARTICLES

	const articles = [
	// {
	// 	date:"May 05, 2024",
	// 	quote:"\"The duo Helgemo and Pedersen have made a scifi-trilogy that manages to be silly, epic, cute and whimsical.\"",
	// 	linkText:"Empirix",
	// 	link:"https://www.empirix.no/udugelege-heltar-endelaus-anmeldelse/"
	// },
	// {
	// 	date:"June 04, 2023",
	// 	quote:"\"No-Good Heroes is one of my favourite comic book series! The book is packed full of humour, excitement and gore, which makes it a fantastic reading experience.\"",
	// 	linkText:"Noras Bokblogg",
	// 	link:"https://noravinjor.blogg.no/bokomtale-udugelege-heltar-2-nevar-av-stal.html"
	// },
	];


///// VIDEOS

	const videos = [
	// {
	// 	src:"https://www.youtube.com/watch?v=0KtwTOFrQDk"
	// },
	// {
	// 	src:"https://www.youtube.com/watch?v=Ir_n3BnEkoU"
	// },
	// {
	// 	src:"https://www.youtube.com/watch?v=7ExrNcusV7s"
	// },
	];



///// GALLERY

		const galleryImages = [
		{
			src: img_01,
			alt: "gallery item",
		},
		{
			src: img_02,
			alt: "gallery item",
		},
		{
			src: img_03,
			alt: "gallery item",
		},
		{
			src: img_04,
			alt: "gallery item",
		},
		{
			src: img_05,
			alt: "gallery item",
		},
		{
			src: img_06,
			alt: "gallery item",
		},
		{
			src: img_07,
			alt: "gallery item",
		},
		// {
		// 	src: img_08,
		// 	alt: "gallery item",
		// },
		// {
		// 	src: img_09,
		// 	alt: "gallery item",
		// },
	];



///// CODE

	return(

		<div className="series-page-container">
	      
	      	<Navbar />

			<div className='background-image-wrapper'>
	        	<div className='background-image-gradient' />
	        	<img alt={'Background'} src={BackgroundArt} className='background-art' />
			</div>


			<div className="content-wrapper">

				<SeriesGeneralInfo
					seriesLogo={ Logo }
					seriesDescription={ seriesDescription }
					tapasLink={ tapasLink }
					tapasLogo={ TapasLogo }
					webtoonLink={ webtoonLink }
					webtoonLogo={ WebtoonLogo }
					readOnlineLanguage="Coming Soon!"
					seriesTags={ tags }
					online={ isOnline }
					/>

				<div className="main-body-wrapper">
			    	<div className="series-page-split-section-handler">
				    	
				    	<div className="series-split-section-left">
					    	<div className="main-body-header">
					    	Book Releases</div>
					    	<div className={ myVolumes.length != 0 ?"book-release-wrapper" : "no-releases"}>
					    		{
					    			myVolumes.map(val=> (
					    				myVolumes.length > 0 ?
								    	<BookRelease 
										image=<img src={ val.thumbnail } alt={ val.alt } className="book-thumbnail-image"/>
										title={ val.title }
										purchaseLink={ val.purchaseLink }
										language= { val.language }
								    	/>
								    	:
								    	null
					    			))
					    		}
					    		{
					    			myVolumes.length === 0 ? "No Releases" : null
					    		}
					    	</div>
				    	</div>
				    	
				    	<div className="series-split-section-right">
				    		<Spacer />
					    	<div className="main-body-header">
					    	Credits</div>
					    	<div className="creator-credit-wrapper">
					    		{
					    			seriesCreators.map(val=> (
						    		<CreatorCredit
						    			name={ val.name } title={ val.title }
						    		/>
						    		))
					    		}
				    		</div>

			    		</div>
		    		</div>
		    	</div>

		    	<div className="main-body-wrapper">
			    	<PressAndReviews 
			    	pressArticles = { articles }
			    	/>
		    	</div>

		    	<div className="main-body-wrapper">
			    	<VideoBlock 
			    	seriesVideos = { videos }
			    	/>
		    	</div>


{/*SKETCHES,CONCEPTS and ILLUSTRATIONS*/}

		    	<div className="main-body-wrapper">
	    			<Gallery 
	    			images = { galleryImages }
	    			/>
		    	</div>


	    	</div>

{/*FOOTER*/}

		     <div className="footer-wrapper">
		     <Footer />
		     </div>

      	</div>
		);
}
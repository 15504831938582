import React from 'react';
import './navbar.scss';
import { ReactComponent as Logo } from './../Assets/zilo-media-logo.svg';
import BackArrowIcon from './backArrowIcon';
import { Link, useLocation } from 'react-router-dom';
import Menu from './../menu';
import { useState } from 'react';



const Navbar = () => {

	const location = useLocation();
	let isHome = false;

	if (location.pathname != ("/" || "/home") ) {
		isHome = false;
	}
	else if (location.pathname == ("/" || "/home") ){
		isHome = true;
	}

	// Define state
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	// Define function that will open the menu
	const openMenu = () => {
		setIsMenuOpen(true);
	}

	// Defint function that wil close the menu
	const closeMenu = () => {
		setIsMenuOpen(false);
	}



	return(
		<>
		<Menu show={isMenuOpen}/>
		<div className="navbar-container">
		
			<div className="navbar-content-wrapper">
				{/*<Link to="/home" className="navbar-back-button">*/}
				<div className="navbar-menu-button" onClick={()=> setIsMenuOpen(!isMenuOpen) }>
				{!isMenuOpen ? "Menu" : "Close"}
				</div>
				<Link to="/" className={ !isHome ? "navbar-back-button" : "no-back-button"}>
					<BackArrowIcon />
					<span>Back</span>
				</Link>

			</div>
		</div>
		</>

	);
}

export default Navbar;
import React from 'react';
import './hero.scss'
import HeroImage from './../Assets/bounties/BountiesBannerArt.jpg'
import TagPill from './tagPill';
import { Link } from 'react-router-dom'


const SimpleHero = () => {
	const productQuote = 'A brand new webcomic, coming soon!'
	const pageLink = '/series/Bounties'

  return (
  	<div className="simple-hero-backplate">

  		<Link to={ pageLink } className="simple-hero-container">
  			<img alt={'Hero'} src={HeroImage} className='hero-simple-image' />
		</Link>
    </div>
  );
}

export default SimpleHero;
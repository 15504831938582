import React from 'react';
import './exploreSeries.scss'
import SeriesThumbnail from './seriesThumbnail'
import NGHThumbnail from './../Assets/no-good-heroes/ngh-thumb.jpg';
import KeepBusyThumbnail from './../Assets/keep-busy/kb-thumb.jpg';
import EosThumbnail from './../Assets/eos/eos-thumb.jpg';
import TomeOfTinyTalesThumbnail from './../Assets/tome-of-tiny-tales/ttt-thumb.jpg';
import BountiesThumbnail from './../Assets/bounties/bounties-thumb.jpg';


const ExploreSeries = () => {



	return(

		<div className="explore-series-container">

			<SeriesThumbnail
			image=<img src={ BountiesThumbnail } alt="Bounties" className="series-thumbnail-image"/>
			title="Bounties"
			link="Bounties"
			/>

			<SeriesThumbnail 
			image=<img src={ NGHThumbnail } alt="No-Good Heroes" className="series-thumbnail-image"/>
			title="No-Good-Heroes"
			link="No-Good-Heroes"
			/>

			<SeriesThumbnail
			image=<img src={ KeepBusyThumbnail } alt="Keep Busy" className="series-thumbnail-image"/>
			title="Keep Busy"
			link="Keep-Busy"
			/>

			<SeriesThumbnail
			image=<img src={ EosThumbnail } alt="Eos" className="series-thumbnail-image"/>
			title="Eos"
			link="Eos"
			/>

			<SeriesThumbnail
			image=<img src={ TomeOfTinyTalesThumbnail } alt="Tome of Tiny Tales" className="series-thumbnail-image"/>
			title="Tome of Tiny Tales"
			link="Tome-of-Tiny-Tales"
			/>

		</div>

	);
}

export default ExploreSeries;
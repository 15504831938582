import React from 'react';
import './seriesPage.scss';
import Logo from './../Assets/eos/EOS_Logo.png';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import BackgroundArt from './../Assets/eos/eos-background.jpg';
import Navbar from './../Components/navbar';
import TagPill from './../Components/tagPill';
import Footer from './../Components/footer';
import TapasLogo from './../Assets/external-logos/TAPAS_Logo.jpeg';
import WebtoonLogo from './../Assets/external-logos/WEBTOON_Logo.png';
import SeriesGeneralInfo from './../Components/seriesGeneralInfo';
import ReleasesAndCredits from './../Components/releasesAndCredits';
import PressAndReviews from './../Components/pressAndReviews';
import VideoBlock from './../Components/videoBlock';
import './../Components/videoBlock.scss';
import Gallery from './../Components/gallery';
import PressBlock from './../Components/pressBlock';
import BookRelease from './../Components/bookRelease';
import './../pages/seriesPage.scss';
import CreatorCredit from './../Components/creatorCredit';
import Spacer from './../Components/spacer';

/// BOOK COVERS
import BookThumbnail from './../Assets/no-good-heroes/UH-Bok_1-omslag-WEB.jpg';
import BookThumbnail2 from './../Assets/no-good-heroes/UH-Bok_2-omslag-WEB.jpg';
import BookThumbnail3 from './../Assets/no-good-heroes/UH-Bok_3-omslag-WEB.png';

/// GALLERY IMAGES
import img_01 from './../Assets/eos/CollectorIllustration_WEB.jpg'
import img_02 from './../Assets/eos/IlyaActionPose_WEB.jpg'
import img_03 from './../Assets/eos/IlyaContemplatingHerStrength_WEB.jpg'
import img_04 from './../Assets/eos/TulaInForest_WEB.jpg'


export default function Eos() {

///// TITLES AND LINKS

	const seriesTitle = 'Eos'
	const tapasLink = ''
	const webtoonLink = ''


////// TAGS
	const tags = [
		{ 
			tag: "Science Fiction",
			isWarning:false
		},
		{ 
			tag: "Fantasy",
			isWarning:false
		},
		{
			tag: "Moderate Violence",
			isWarning:true
		},
		{
			tag: "Partial Nudity",
			isWarning:true
		}
	];

///// RELEASES AND CREDITS

	const isOnline = false;

	const myVolumes = [

	];

	const seriesCreators = [
		{
			name: "Lars Erik Helgemo",
			title: "Artist"
		},
	];	


///// ARTICLES

	const articles = [
	// {
	// 	date:"May 05, 2024",
	// 	quote:"\"The duo Helgemo and Pedersen have made a scifi-trilogy that manages to be silly, epic, cute and whimsical.\"",
	// 	linkText:"Empirix",
	// 	link:"https://www.empirix.no/udugelege-heltar-endelaus-anmeldelse/"
	// },
	];


///// VIDEOS

	const videos = [
	// {
	// 	src:"https://www.youtube.com/watch?v=0KtwTOFrQDk"
	// },
	];



///// GALLERY

		const galleryImages = [
		{
			src: img_01,
			alt: "gallery item",
		},
		{
			src: img_02,
			alt: "gallery item",
		},
				{
			src: img_03,
			alt: "gallery item",
		},
				{
			src: img_04,
			alt: "gallery item",
		},
	];



///// CODE

	return(

		<div className="series-page-container">
	      
	      	<Navbar />

			<div className='background-image-wrapper'>
	        	<div className='background-image-gradient' />
	        	<img alt={'Background'} src={BackgroundArt} className='background-art' />
			</div>


			<div className="content-wrapper">

				<SeriesGeneralInfo
					seriesLogo={ Logo }
					seriesDescription={ 
						<span>
						<b>No one has returned from the Maw,</b> a dark and fearsome cave system which people believe connects 
						their small nation Jayu to an outside world. Follow Ilya and Tula's adventure into the unknown as they 
						search for long lost friends and uncover the mysteries of the world.<br /> <br />
						Eos is currently in progress, stay tuned for updates!
						</span> }
					tapasLink={ tapasLink }
					tapasLogo={ TapasLogo }
					webtoonLink={ webtoonLink }
					webtoonLogo={ WebtoonLogo }
					readOnlineLanguage="Not Released"
					seriesTags={ tags }
					Online= { isOnline }
					/>

				<div className="main-body-wrapper">
			    	<div className="series-page-split-section-handler">
				    	
				    	<div className="series-split-section-left">
					    	<div className="main-body-header">
					    	Book Releases</div>
					    	<div className={ myVolumes.length != 0 ?"book-release-wrapper" : "no-releases"}>
					    		{
					    			myVolumes.map(val=> (
					    				myVolumes.length > 0 ?
								    	<BookRelease 
										image=<img src={ val.thumbnail } alt={ val.alt } className="book-thumbnail-image"/>
										title={ val.title }
										purchaseLink={ val.purchaseLink }
										language= { val.language }
								    	/>
								    	:
								    	null
					    			))
					    		}
					    		{
					    			myVolumes.length === 0 ? "There are no book releases for this series yet." : null
					    		}
					    	</div>
				    	</div>
				    	
				    	<div className="series-split-section-right">
				    		<Spacer />
					    	<div className="main-body-header">
					    	Credits</div>
					    	<div className="creator-credit-wrapper">
					    		{
					    			seriesCreators.map(val=> (
						    		<CreatorCredit
						    			name={ val.name } title={ val.title }
						    		/>
						    		))
					    		}
				    		</div>

			    		</div>
		    		</div>
		    	</div>

		    	<div className="main-body-wrapper">
			    	<PressAndReviews 
			    	pressArticles = { articles }
			    	/>
		    	</div>

		    	<div className="main-body-wrapper">
			    	<VideoBlock 
			    	seriesVideos = { videos }
			    	/>
		    	</div>

		    	<div className="main-body-wrapper">
	    			<Gallery 
	    			images = { galleryImages }
	    			/>
		    	</div>


	    	</div>

{/*FOOTER*/}

		     <div className="footer-wrapper">
		     <Footer />
		     </div>

      	</div>
		);
}
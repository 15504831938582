import React, { useState} from 'react';
import './imageGallery.scss';
import { ReactComponent as CloseIcon } from './../Assets/CloseIcon.svg';

const ImageGallery = (props) => {	


	return(
		<>
		<div className="image-gallery-modal">
			<img src={ props.img } alt="source" className="image" />
			<CloseIcon onClick={ props.onClose } className="close-icon" />
		</div>
		<div onClick={ props.onClose } className="gallery-backdrop" />
		</>

	);
}

export default ImageGallery;
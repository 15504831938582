import React from 'react';
import './hero.scss'
import HeroImage from './../Assets/no-good-heroes/Hero_UH3.jpg'
import TagPill from './tagPill';


const Hero = () => {
	const productTitle = 'Udugelege Heltar, Volum 3: Endelaus'
	const productReleaseDate = 'April 26, 2024'
	const productQuote = 'The final book in the trilogy about the No-Good Heroes.'
	const genreTag = 'Superhero'
	const purchaseLink = 'https://www.uberpress.no/products/udugelege-heltar-volum-3-endelaus'
	const readLink = 'https://tapas.io/series/No-Good-Heroes'

  return (
  	<div className="hero-backplate">

  		<div className="hero-container">
  			<img alt={'Hero'} src={HeroImage} className='hero-image' />
	  		
	  		<div className="hero-info-container">
	  			<div className="hero-info-text-wrapper">
				    <div className="homepage-hero">
				    		{ productTitle }
					</div>
					<div className="homepage-hero-tags-wrapper">
						<TagPill tag="Norwegian" />
				    	<TagPill tag="Superhero"/>
				    	<TagPill tag="Comedy"/>
				    	<TagPill tag="Cartoon Violence" isWarning/> 
				    	<TagPill tag="Strong Language" isWarning/> 
					</div>
				    <div className="homepage-hero-description">
				    	{ productQuote }
					</div>
					<div className="homepage-hero-release-date">
				    	Released: { productReleaseDate }
					</div>
				</div>

				<div className="hero-cta-btn-wrapper">
					<button className="big-cta-btn" onClick={()=> window.open( purchaseLink, "_blank")}>Buy Physical Edition</button>
					<button className="big-cta-btn" onClick={()=> window.open( readLink, "_blank")}>Read Online <span className="language-spec">English</span></button>
				</div>
			</div>
			
		</div>
    </div>
  );
}

export default Hero;
import React from 'react';
import { ReactComponent as Logo } from './Assets/zilo-media-logo.svg';
import { ReactComponent as AfterDark } from './Assets/AfterDark-Logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';


const ZiloLogo = () => {

  const [isAfterDark, setIsAfterDark] = useState(false);
  const location = useLocation();


  useEffect(() => {
    if (location.pathname === '/After-Dark') {
      setIsAfterDark(true);
    } else {
      setIsAfterDark(false);
    }
  }, [location.pathname]);


  

  function DisplayLogo(){
    if (isAfterDark) {
      return <Logo />;
    } 
    else {
      return <Logo />;
    }
  }

    return(

      <>
      { DisplayLogo() }
      </>
  );
};

export default ZiloLogo;

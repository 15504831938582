import React from 'react';
import './App.scss';
import './utility.scss';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import ScrollToTop from './Components/scrollToTop';
import ZiloLogo from './ZiloLogo';
import Home from './pages/home';
import SeriesPage from './pages/seriesPage';
import KeepBusy from './pages/KeepBusy';
import Eos from './pages/Eos';
import TomeOfTinyTales from './pages/TomeOfTinyTales';
import About from './pages/about';
import NoPage from './pages/404';
import ResourceCenter from './pages/resourceCenter';
import AfterDark from './pages/afterDark';
import Bounties from './pages/Bounties'



function App() {



  return (
    <div>
    <BrowserRouter>
        <div className="logo-wrapper">
        <ZiloLogo />
    </div>
    
      
      <ScrollToTop />
        <Routes>
          <Route index element={ <Home /> } />
          <Route path="/" element={ <Home /> } />
          <Route path="/home" element={ <Home /> } />
          <Route path="/series/Bounties" element={ <Bounties /> } />
          <Route path="/series/No-Good-Heroes" element={ <SeriesPage /> } />
          <Route path="/series/Eos" element={ <Eos /> } />
          <Route path="/series/Keep-Busy" element={ <KeepBusy /> } />
          <Route path="/series/Tome-of-Tiny-Tales" element={ <TomeOfTinyTales /> } />
          {/*<Route path="/After-Dark" element={ <AfterDark /> } />*/}
          <Route path="/about" element={ <About /> } />
          {/*<Route path="/resource-center" element={ <ResourceCenter /> } />*/}
          <Route path="*" element={ <NoPage /> } />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;

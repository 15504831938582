import React from 'react';
import './../App.scss';
import BackgroundArt from './../Assets/eos/CollectorIllustration_WEB.jpg';
import Hero from './../Components/hero';
import ExploreSeries from './../Components/exploreSeries';
import ComicSpotlight from './../Components/comicSpotlight';
import ButtonBlock from './../Components/buttonBlock';
import Footer from './../Components/footer';
import './../utility.scss';
import Navbar from './../Components/navbar';
import TagPill from './../Components/TagPill';
import Patreon from './../Assets/PATREON_SYMBOL_1_WHITE_RGB.svg';
import ziloAfterDarkLogo from './../Assets/AfterDark.png';
import SimpleHero from './../Components/hero-simple';

export default function Home() {

  return (
    <div className="main">
      <Navbar/>
      
      <div className='background-image-wrapper'>
          <div className='background-image-gradient' />
          <img alt={'Background'} src={BackgroundArt} className='background-art' />
      </div>
      
      <div className="content-wrapper">

{/*HERO*/}

      <div className="main-body-wrapper">
      <SimpleHero />
      </div>

        <div className="main-body-wrapper">
        <Hero />
        </div>

{/*EXPLORE SERIES*/}

        <div className="main-body-wrapper">
          <div>
            <div className="main-body-header">
            Explore Series
            </div>
            <ExploreSeries />
          </div>
        </div>

{/*COMIC SPOTLIGHT*/}

        <div className="main-body-wrapper">
          <div>
            <div className="main-body-header">
            Comic Spotlight
            </div>
            <ComicSpotlight />
          </div>
        </div>

{/*BUTTON BLOCKS*/}

        <div className="main-body-button-block-wrapper">
{/*          <ButtonBlock superTitle="Want to make comics?" title="Helpful Tools, Links and Tips" url="/resource-center" />
          <ButtonBlock superTitle="Discover Norwegian Comics" title="Visit Norske Tegneserier" />*/}
          <ButtonBlock img={ Patreon } superTitle="Want to help me out?" title="Become a Patreon" url="https://patreon.com/ZiloMediaComics"/>
          {/*<ButtonBlock img={ ziloAfterDarkLogo } superTitle="Do you like it spicy? Have a taste." title="Zilo After Dark" url="After-Dark"/>*/}
        </div>

      </div>

{/*FOOTER*/}

      <div className="footer-wrapper">
      <Footer />
      </div>

    </div>

  );
}
import React from 'react';
import './about.scss';
import { Link } from 'react-router-dom';
import Navbar from './../Components/navbar';
import AboutPicture from './../Assets/about-image.jpg'
import BackgroundArt from './../Assets/about-background-image.jpg'

export default function About() {

	const rainLink = "https://rain-games.com/";
	const inkyLink = "https://inky-pen.com/";

  	return (
  		<>
  		<Navbar />
  		<div className="about-page-container">
  		<div className='background-image-wrapper'>
	       	<div className='background-image-gradient' />
	       	<img alt={'Background'} src={BackgroundArt} className='background-art' />
		</div>
		  	<div className="about-page-header-wrapper">
			  	<div className="about-page-header-title">
			  	About
			  	</div>

			  	<div className="about-page-header-subtitle">
			  	Zilo Media
			  	</div>
		  	</div>
		  	<div className="about-details-wrapper">
		  		<div className="about-page-image-wrapper">
		  			<img src={ AboutPicture } alt="Lars" className="about-page-image" />
		  		</div>
				 <div className="about-page-text">
				 Zilo Media is run by Lars, the artist behind the duo that created No-Good Heroes. He’s a passionate 
				 story teller with 3 books and 10 short stories under his belt as well as over a hundred comic strips. 
				 While drawing and writing is his primary way of expression, Lars is a sponge for acquiring new skills. 
				 Having worked as Lead 3D Character Artist, Animator, Rigger and UI designer for <span className="about-link" onClick={()=> window.open( rainLink, "_blank")}>Rain Games</span> and Lead 
				 UI/UX designer for <span className="about-link" onClick={()=> window.open( inkyLink, "_blank")}>InkyPen</span> since the company was founded. One of his primary hobbies is expanding that 
				 skill set. His most recent additions being composing music and front end development, making him a one 
				 man army on the creative battlefield. The one hurdle he hasn’t cleared yet is his long term project of 
				 learning Japanese... but with his track record we’re sure he’ll get there someday.
			  	</div>

		  	</div>
	  	</div>
	  	</>
  );
}
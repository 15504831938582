import React from 'react';
import './seriesPage.scss';
import Logo from './../Assets/tome-of-tiny-tales/Logo.png';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import BackgroundArt from './../Assets/tome-of-tiny-tales/Solriket_026.jpg';
import Navbar from './../Components/navbar';
import TagPill from './../Components/tagPill';
import Footer from './../Components/footer';
import TapasLogo from './../Assets/external-logos/TAPAS_Logo.jpeg';
import WebtoonLogo from './../Assets/external-logos/WEBTOON_Logo.png';
import SeriesGeneralInfo from './../Components/seriesGeneralInfo';
import ReleasesAndCredits from './../Components/releasesAndCredits';
import PressAndReviews from './../Components/pressAndReviews';
import VideoBlock from './../Components/videoBlock';
import './../Components/videoBlock.scss';
import Gallery from './../Components/gallery';
import PressBlock from './../Components/pressBlock';
import BookRelease from './../Components/bookRelease';
import './../pages/seriesPage.scss';
import CreatorCredit from './../Components/creatorCredit';
import Spacer from './../Components/spacer';

/// BOOK COVERS
import BookThumbnail from './../Assets/tome-of-tiny-tales/24t2023.jpg';
import BookThumbnail2 from './../Assets/tome-of-tiny-tales/trolliord.jpg';
import BookThumbnail3 from './../Assets/tome-of-tiny-tales/overtrykk2022.jpg';
import BookThumbnail4 from './../Assets/tome-of-tiny-tales/overtrykk2021.png';
import BookThumbnail5 from './../Assets/tome-of-tiny-tales/Kittelsens_skog.jpg';

/// GALLERY IMAGES
import img_01 from './../Assets/tome-of-tiny-tales/OCX - 24T - 2022 - EN_001.jpg'
import img_02 from './../Assets/tome-of-tiny-tales/1.jpg'
import img_03 from './../Assets/tome-of-tiny-tales/Skrømt_ENnew_001.jpg'
import img_04 from './../Assets/tome-of-tiny-tales/Solriket_001.jpg'
import img_05 from './../Assets/tome-of-tiny-tales/Husk_001.jpg'
import img_06 from './../Assets/tome-of-tiny-tales/HeightRef_Print.jpg'


export default function TomeOfTinyTales() {

///// TITLES AND LINKS

	const seriesTitle = 'Tome of Tiny Tales'
	const tapasLink = 'https://tapas.io/series/TomeOfTinyTales'
	const webtoonLink = 'https://www.webtoons.com/en/canvas/tome-of-tiny-tales/list?title_no=354100'


////// TAGS
	const tags = [
		{ 
			tag: "Fantasy",
			isWarning:false
		},
		{ 
			tag: "Horror",
			isWarning:false
		},
		{ 
			tag: "Adventure",
			isWarning:false
		},
		{ 
			tag: "Short Story",
			isWarning:false
		},
		{ 
			tag: "Cartoon Violence",
			isWarning:true
		},
		{ 
			tag: "Gore",
			isWarning:true
		},
		{ 
			tag: "Suggestive",
			isWarning:true
		},
	];

///// RELEASES AND CREDITS

	const isOnline = true

	const myVolumes = [
		{
			title: "Bergen 24t, 2023",
			alt: "25 hour comics",
			thumbnail: BookThumbnail,
			purchaseLink: "https://www.uberpress.no/products/bergen-24-timers-tegneseriemaraton-2023?_pos=2&_sid=4e77dc604&_ss=r",
			language: "Norwegian"
		},
		{
			title: "Troll i Ord",
			alt: "Anthology",
			thumbnail: BookThumbnail2,
			purchaseLink: "https://www.ark.no/produkt/boker/skjonnlitteratur/troll-i-ord-9788293254409",
			language: "Norwegian"
		},
		{
			title: "Overtrykk 2022",
			alt: "Anthology",
			thumbnail: BookThumbnail3,
			purchaseLink: "https://www.uberpress.no/products/overtrykk-2022?_pos=4&_sid=d68212bdf&_ss=r",
			language: "Norwegian"
		},
		{
			title: "Overtrykk 2021",
			alt: "Anthology",
			thumbnail: BookThumbnail4,
			purchaseLink: "https://www.ark.no/produkt/boker/skjonnlitteratur/overtrykk-2021-9788293254485",
			language: "Norwegian"
		},
		{
			title: "Kittelsens Skog",
			alt: "Anthology",
			thumbnail: BookThumbnail5,
			purchaseLink: "https://www.ark.no/produkt/boker/skjonnlitteratur/kittelsens-skog-9788293254355",
			language: "Norwegian"
		},
	];

	const seriesCreators = [
		{
			name: "Lars Erik Helgemo",
			title: "Artist"
		},
		{
			name: "Are Edvardsen",
			title: "Editor (Kingdom of the Sun, Memor, Trollbound, Shade)"
		},
		{
			name: "Regine Toften Holst",
			title: "Assistant Editor (Kingdom of the Sun, Memor)"
		},
		{
			name: "Peter W. Meldahl",
			title: "Co-Writer (Memor)"
		},
	];	


///// ARTICLES

	const articles = [
	{
		date:"December 12, 2021",
		quote:"Kingdom of the Sun: \"It is clearly building towards an ending with a moral lesson, but I must admit I was taken aback by the explosively emotional conclusion, and the final twist of the story.\"",
		linkText:"Empirix",
		link:"https://www.empirix.no/overtrykk-2021-anmeldelse/"
	},
	];


///// VIDEOS

	const videos = [
	// {
	// 	src:"https://www.youtube.com/watch?v=0KtwTOFrQDk"
	// },
	];



///// GALLERY

		const galleryImages = [
		{
			src: img_01,
			alt: "gallery item",
		},
		{
			src: img_02,
			alt: "gallery item",
		},
		{
			src: img_03,
			alt: "gallery item",
		},
		{
			src: img_04,
			alt: "gallery item",
		},
		{
			src: img_05,
			alt: "gallery item",
		},
				{
			src: img_06,
			alt: "gallery item",
		},
	];



///// CODE

	return(

		<div className="series-page-container">
	      
	      	<Navbar />

			<div className='background-image-wrapper'>
	        	<div className='background-image-gradient' />
	        	<img alt={'Background'} src={BackgroundArt} className='background-art' />
			</div>


			<div className="content-wrapper">

				<SeriesGeneralInfo
					seriesLogo={ Logo }
					seriesDescription={<span><b>A Collection of Enchanting Short Stories</b><br /><br />The Tome of tiny tales is a collection of short stories of different genres, tones and art style. The ones that are maybe a bit too small to make an entire new series out of. Here you'll find cute stories, horror stories, adventures and more, but they are usually in the realm of fantasy!</span>}
					tapasLink={ tapasLink }
					tapasLogo={ TapasLogo }
					webtoonLink={ webtoonLink }
					webtoonLogo={ WebtoonLogo }
					readOnlineLanguage="English"
					seriesTags={ tags }
					online={ isOnline }
					/>

				<div className="main-body-wrapper">
			    	<div className="series-page-split-section-handler">
				    	
				    	<div className="series-split-section-left">
					    	<div className="main-body-header">
					    	Book Releases</div>
					    	<div className={ myVolumes.length != 0 ?"book-release-wrapper" : "no-releases"}>
					    		{
					    			myVolumes.map(val=> (
					    				myVolumes.length > 0 ?
								    	<BookRelease 
										image=<img src={ val.thumbnail } alt={ val.alt } className="book-thumbnail-image"/>
										title={ val.title }
										purchaseLink={ val.purchaseLink }
										language= { val.language }
								    	/>
								    	:
								    	null
					    			))
					    		}
					    		{
					    			myVolumes.length === 0 ? "There are no book releases for this series yet." : null
					    		}

					    	</div>
					    	
				    	</div>

				    	

				    	<div className="series-split-section-right">
				    	<Spacer />
					    	<div className="main-body-header">
					    	Credits</div>
					    	<div className="creator-credit-wrapper">
					    		{
					    			seriesCreators.map(val=> (
						    		<CreatorCredit
						    			name={ val.name } title={ val.title }
						    		/>
						    		))
					    		}
				    		</div>

			    		</div>
		    		</div>
		    	</div>

		    	<div className="main-body-wrapper">
			    	<PressAndReviews 
			    	pressArticles = { articles }
			    	/>
		    	</div>

		    	<div className="main-body-wrapper">
			    	<VideoBlock 
			    	seriesVideos = { videos }
			    	/>
		    	</div>

		    	<div className="main-body-wrapper">
	    			<Gallery 
	    			images = { galleryImages }
	    			/>
		    	</div>


	    	</div>

{/*FOOTER*/}

		     <div className="footer-wrapper">
		     <Footer />
		     </div>

      	</div>
		);
}
import React from 'react';
import TagPill from './tagPill';
import './../pages/seriesPage.scss';

const SeriesGeneralInfo = (props) => {

	let tags =  props.seriesTags ;

	return(
		<>
	    	<div className="main-body-wrapper">
			    <img src={ props.seriesLogo } className="series-logo" />
		    </div>
		    

		    <div className="main-body-wrapper">
			    <div className="series-page-general-info-wrapper">
				    <div className="series-page-general-info-position">
				    	<div className="general-info-left">
					    	<div className="series-page-tag-wrapper">
					    		{
					    			tags.map(val =>(
					    				<TagPill tag={ val.tag } isWarning={val.isWarning} />
					    				))
					    		}
					    	</div>
					    	<div className="series-page-description"> { props.seriesDescription }
					    	</div>
				    	</div>

				    	<div className="general-info-right">
				    		<div className="general-info-right-header">
					    		Read Online
					    		<TagPill tag={ props.readOnlineLanguage } />
				    		</div>
				    		<div className="external-logo-wrapper">
					    		<a href={ props.tapasLink } target="_blank">
					    			<img alt={'Tapas'} src={ props.tapasLogo } className={props.online === true ? 'external-logo' : 'disabled-logo'} />
					    		</a>
					    		<a href={ props.webtoonLink } target="_blank" >
					    			<img alt={'Webtoon'} src={ props.webtoonLogo } className={props.online === true ? 'external-logo' : 'disabled-logo' }/>
					    		</a>
				    		</div>
				    	</div>

			    	</div>
		    	</div>
	    	</div>
	    </>

		);
}

export default SeriesGeneralInfo;

